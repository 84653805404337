<template>
  <div id="hadSelectcourse">
    <el-dialog
      title="已选课程"
      class="dialogVisible"
      width="850px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_bottom">
        <el-table
          :row-key="getRowkey"
          @selection-change="selectd"
          @row-click="handleRowClick"
          style="height: 315px"
          :data="newcourse"
          ref="dialogTable"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
        >
          <el-table-column
            prop="prop"
            label=""
            type="selection"
          ></el-table-column>

          <el-table-column prop="prop" label="课程">
            <template scope="scope">
              <div class="className">
                <img :src="scope.row.photo" alt="" />
                <p :title="scope.row.name">{{ scope.row.name }}</p>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="prop" label="类型">
            <template slot-scope="scope">
              <p>
                {{
                  scope.row.course_type == 1
                    ? '直播课'
                    : scope.row.course_type == 2
                    ? '小班课'
                    : scope.row.course_type == 3
                    ? '录播课'
                    : '系列课'
                }}
              </p>
            </template>
          </el-table-column>

          <el-table-column prop="prop" label="售价">
            <template slot-scope="scope">
              <div class="titleActivit">
                <p style="color: #ff3535">
                  {{ scope.row.price == 0 ? '免费' : '￥' + scope.row.price }}
                </p>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="prop" label="操作">
            <template slot-scope="{ row }">
              <el-button type="text" @click.stop="remove(row.course_id)">
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="bottom">
          <span class="zi3">已选 {{ listSave.length }} 条</span>
          <el-button
            type=""
            @click="removelist"
            size="medium"
            style="width: 120px"
          >
            批量移除
          </el-button>
        </div>
        <div class="pagination">
          <el-pagination
            v-if="course.length > pagesize"
            @current-change="currentChange"
            background
            layout="prev, pager, next"
            :total="course.length"
            :page-size="pagesize"
          ></el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'hadSelectcourse',
  data() {
    return {
      coursenew: [], //深拷贝course的数据
      newcourse: [],
      pagesize: '', //分页数
      listSave: [],
      dialogVisible: false,
    }
  },
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.pagesize = Number(
      localStorage.getItem('pageSize') || this.$store.state.pageSize[0]
    )

    let list = _.merge([], this.course)
    switch (this.pagesize) {
      case 1:
        this.newcourse = list.splice(0, 1)
        break
      case 15:
        this.newcourse = list.slice(0, 15)
        break
      case 30:
        this.newcourse = list.slice(0, 30)
        break
      case 45:
        this.newcourse = list.slice(0, 45)
        break
      case 60:
        this.newcourse = list.slice(0, 60)
        break
    }
    this.$nextTick(() => {
      this.coursenew = _.assign([], this.course)
    })
  },
  methods: {
    //批量移除
    removelist() {
      let arry1 = []
      this.course.forEach((item, index) => {
        if (
          JSON.stringify(this.listSave).indexOf(
            JSON.stringify(this.course[index])
          ) == -1
        ) {
          arry1.push(this.course[index])
        }
      })

      this.newcourse = arry1
      this.$emit('coursenew', arry1)
    },
    //单个移除
    remove(id) {
      this.coursenew = this.coursenew.filter(f => f.course_id != id)
      this.$emit('coursenew', this.coursenew)
      this.newcourse = this.newcourse.filter(f => f.course_id != id)
    },
    //当前页数的变化
    currentChange(val) {
      let list = _.merge([], this.course)
      switch (this.pagesize) {
        case 1:
          this.newcourse = list.splice((val - 1) * 1, 1)
          break
        case 15:
          this.newcourse = list.splice((val - 1) * 15, 15)
          break
        case 30:
          this.newcourse = list.splice((val - 1) * 30, 30)
          break
        case 45:
          this.newcourse = list.splice((val - 1) * 45, 45)
          break
        case 60:
          this.newcourse = list.splice((val - 1) * 60, 60)
          break
      }
    },
    handleRowClick(row) {
      this.$refs.dialogTable.toggleRowSelection(row)
    },
    getRowkey(row) {
      return row.course_id
    },
    selectd(val) {
      //点击选择暂存选项数组
      this.listSave = val
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
      this.$emit('course', this.course)
    },
  },
}
</script>
<style lang="scss" scoped>
#hadSelectcourse {
  .pagination {
    // margin: 30px auto;
    text-align: center;
  }
  .className {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    img {
      width: 40px;
      height: 22px;
    }

    p {
      margin-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    background: #aaaaaa;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }
  .dialogVisible {
    ::v-deep .pagination {
      padding: 30px 0 20px;
    }

    .bottom {
      margin-top: 30px;
      .zi3 {
        margin-right: 10px;
        font-size: 14px;
        color: #333333;
        line-height: 19px;
      }
    }
    ::v-deep .el-dialog {
      height: 518px;
    }
    ::v-deep .el-table__body-wrapper {
      height: 268px;
      overflow: auto;
    }
    ::v-deep .el-dialog__title {
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
    ::v-deep .el-dialog__header {
      padding-top: 14px;
    }
    ::v-deep .el-dialog__body {
      padding-top: 20px;
    }
    .dialogVisible_bottom {
      margin: 0 auto;
      .dialogVisible_bottom_font1 {
        font-size: 16px;
        text-align: center;
        color: #4a4a4a;
        line-height: 21px;
      }
      img {
        // margin-left: 77px;
        // margin-left: 9px;
      }
    }
  }
}
</style>
