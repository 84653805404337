<template>
  <!-- 选择章节弹窗 -->
  <div id="selectChapter">
    <el-dialog
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="false"
      @close="cancel"
    >
      <div slot="title" class="title">
        <span class="name">选择章节</span>
        <span class="tips">
          提示：列表已过滤系列课，如果课程属于系列课，在单个课程关联作业后，作业同样附属于系列课下
        </span>
      </div>
      <div class="dialogVisible_bottom">
        <div class="header">
          <el-select
            size="medium"
            v-model="post.status"
            placeholder="请选择"
            style="width: 140px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            size="medium"
            v-model="post.course_type"
            placeholder="请选择"
            class="ml20 mr20"
            style="width: 140px"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            size="medium"
            v-model="post.search"
            placeholder="输入课程名称搜索"
            style="width: 216px"
          ></el-input>
        </div>
        <div class="main_contain">
          <div class="left">
            <div
              class="course"
              v-for="item in getCourselist"
              :key="item.course_id"
              @click="selectcouse(item)"
              :class="{ bg: isselectlist.course_id == item.course_id }"
            >
              <div class="tu">
                <img :src="item.photo" alt="" />
              </div>
              <div class="name" :title="item.name">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="right">
            <div class="tips">提示：已添加的章节不在列表展示</div>
            <div class="chapter">
              <div
                class="zhangjie"
                v-for="item in getScheduleList"
                :key="item.correlation_id"
                @click="
                  allScheduleChecked = Object.assign({}, isselectlist, item)
                "
              >
                <div class="name" title="">
                  {{ item.schedule_name }}
                </div>
                <div
                  class="checkbox"
                  :class="{
                    checked:
                      allScheduleChecked.correlation_id == item.correlation_id,
                  }"
                ></div>
              </div>
            </div>
            <div class="bottom">
              <div class="submit">
                <div class="allselect"></div>
                <div class="btns">
                  <el-button size="medium" @click="cancel">取消</el-button>
                  <el-button type="primary" size="medium" @click="submit">
                    确定
                  </el-button>
                </div>
              </div>
              <div class="describe">
                <span>类型：{{ isselectlist.course_type | gettype }}</span>
                <span>
                  状态：
                  <span v-if="isselectlist.status == 1">已上架</span>
                  <span v-else>已下架</span>
                </span>
                <span>售价：￥{{ isselectlist.price }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'selectChapter',
  data() {
    return {
      isselectlist: {}, //被选中的课程数据
      // 所有课程被选中的章节
      allScheduleChecked: {},
      getScheduleList: [], //章节列表
      getCourselist: [], //课程列表
      post: {
        course_type: 0,
        status: 0,
        search: '',
        homework_id:
          this.$route.params.id !== 'new' ? this.$route.params.id : undefined,
      },
      options: [
        {
          value: 0,
          label: '全部状态',
        },
        {
          value: 1,
          label: '已上架',
        },
        {
          value: 2,
          label: '已下架',
        },
      ],

      options2: [
        {
          value: 0,
          label: '全部类型',
        },
        {
          value: 1,
          label: '直播课',
        },
        {
          value: 2,
          label: '小班课',
        },
        {
          value: 3,
          label: '录播课',
        },
      ],

      value: '',
    }
  },

  filters: {
    gettype(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '直播课'
          break
        case 2:
          text = '小班课'
          break
        case 3:
          text = '录播课'
          break
      }
      return text
    },
  },
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    singleselect: {
      type: Boolean,
      default: false,
    },
    list: [Array],
  },
  watch: {
    post: {
      handler(val) {
        this.post = val
        this.getdata()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getdata()
  },
  methods: {
    submit() {
      if (
        this.allScheduleChecked &&
        Object.keys(this.allScheduleChecked).length > 0
      ) {
        this.$emit('chapters', this.allScheduleChecked)
        this.cancel()
      } else {
        this.$root.prompt('请选择章节')
      }
    },
    //选中的课程
    selectcouse(val) {
      let post = {}
      if (val) {
        post = {
          course_id: val.course_id,
          course_type: val.course_type,
        }
        this.isselectlist = val
      }
      this.allScheduleChecked = {}
      this.getSchedule(post)
    },
    //获取课程章节列表
    getSchedule(val) {
      this.$http({
        url: '/Homework/getScheduleList',
        data: _.assign({}, val, { list: this.list }),
        callback: ({ code, data }) => {
          if (code == 200) {
            this.getScheduleList = data
          }
        },
      })
    },
    //获取课程列表
    getdata() {
      this.$http({
        url: '/Homework/getCourse',
        data: this.post,
        callback: ({ code, data }) => {
          if (code == 200) {
            this.getCourselist = data
            this.selectcouse(data[0])
          }
        },
      })
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.bg {
  background-color: #f0f0f0;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}
::v-deep .el-checkbox__inner::after {
  transform: rotate(45deg) scaleY(1);
  margin: 2px 0 0 2px;
}
::v-deep .el-checkbox__inner {
  background-color: #bbb;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
::v-deep .el-checkbox__inner:hover {
  border-color: #bbb;
}
#selectChapter {
  .title {
    .name {
      width: 56px;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
    }
    .tips {
      font-size: 12px;
      color: #ff3535;
      line-height: 12px;
      margin-left: 20px;
    }
  }
  .dialogVisible {
    ::v-deep .el-dialog__body {
      padding: 0;
    }
  }
  .dialogVisible_bottom {
    height: 465px;
    .header {
      display: flex;
      justify-content: flex-end;
      margin: 20px;
      ::v-deep .el-select__caret {
        height: auto;
      }
    }
    .main_contain {
      height: 404px;
      margin-top: 20px;
      display: flex;
      border-top: 1px #f0f0f0 solid;
      .left {
        width: 300px;
        border-right: 1px #f0f0f0 solid;
        // height: 404px;
        overflow: auto;
        .course {
          display: flex;
          padding: 10px 20px;
          cursor: pointer;
          .tu {
            width: 60px;
            height: 34px;
            margin-right: 10px;
            img {
              width: 60px;
              height: 34px;
            }
          }
          .name {
            width: 190px;
            font-size: 13px;
            color: #333333;
            line-height: 18px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        .course:hover {
          background-color: #f0f0f0;
        }
      }
      .right {
        width: calc(100% - 300px);
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        .tips {
          font-size: 12px;
          color: #ff3535;
          line-height: 12px;
          margin: 20px 20px 8px;
        }
        .chapter {
          // padding: 20px;
          padding-bottom: 0;
          height: 450px;
          overflow: auto;
          .zhangjie {
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: auto;
            line-height: 37px;
            padding: 0 20px;
            .name {
              cursor: pointer;
              width: calc(100% - 28px);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 13px;
              color: #333333;
            }
            &:hover {
              background-color: #f0f0f0;
            }
          }
        }
        .bottom {
          .submit {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px;
            height: 56px;
            ::v-deep .el-checkbox__inner {
              border-radius: 50%;
            }
            .btns {
              .el-button {
                width: 86px;
              }
            }
          }
          .describe {
            width: 100%;
            font-size: 12px;
            color: #666666;
            line-height: 12px;
            background: #eeeeee;
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 9px 0;
          }
        }
      }
    }
  }
}
.checkbox {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  &.checked {
    border-color: #0aa29b;
    background: #0aa29b;
    &:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  &:after {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: #fff;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.15s ease-in;
  }
}
</style>
