<template>
  <div id="newData">
    <div class="newDataAll">
      <div class="seting">
        <el-form
          label-position="left"
          label-width="100px"
          :model="ruleForm"
          :rules="rules"
          ref="ruleFormData"
          class="demo-ruleForm"
          @submit.native.prevent
        >
          <el-form-item label="作业名称" prop="name">
            <el-input
              style="width: 70%"
              placeholder="请输入作业名称"
              v-model="ruleForm.name"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="作业类型" :required="true">
            <div class="work_type">
              <el-radio-group v-model="ruleForm.type" @change="radioChange">
                <el-radio label="1" :disabled="!isnew">关联课程</el-radio>
                <el-radio label="2" :disabled="!isnew">关联章节</el-radio>
                <el-radio label="3" :disabled="!isnew">关联公开课</el-radio>
              </el-radio-group>
              <el-button
                size="medium"
                type="text"
                class="ml60"
                @click="examplesdialogVisible = true"
              >
                查看示例
              </el-button>
              <div class="redcolor fs12" style="line-height: 16px">
                提示：创建后不可修改作业类型！
              </div>
              <div class="typecourse">
                <div class="btn">
                  <template v-if="isnew || ruleForm.theme_count == 0">
                    <el-button
                      style="width: 140px"
                      @click="chooseCourse"
                      v-if="ruleForm.type == '1'"
                    >
                      + 选择课程
                    </el-button>
                    <el-button
                      style="width: 140px"
                      @click="selectchapter"
                      v-else-if="ruleForm.type == '2'"
                    >
                      + 选择章节
                    </el-button>

                    <el-button
                      style="width: 140px"
                      @click="selectOpenCourse"
                      v-else
                    >
                      + 选择公开课
                    </el-button>
                  </template>

                  <!-- <span class="tip1" v-if="ruleForm.type == '1'">
                    已选 {{ course.length }} 门课程
                  </span>
                  <span class="tip1" v-else-if="ruleForm.type == '2'">
                    已选 {{ course.length }} 个章节
                  </span>

                  <span class="tip1" v-else-if="ruleForm.type == '3'">
                    已选 {{ course.length }}
                  </span> -->
                  <div class="mt20">
                    <div
                      class="had_select_course"
                      v-for="(item, index) in course"
                      :key="index"
                    >
                      <div class="tu_name">
                        <div class="tu" v-if="ruleForm.type == 3">
                          <img :src="item.cover_img" alt="" />
                        </div>
                        <div class="tu" v-else>
                          <img :src="item.photo" alt="" />
                        </div>

                        <div
                          v-if="item.schedule_name"
                          class="name"
                          :title="'《' + item.name + '》' + item.schedule_name"
                        >
                          《
                          {{
                            item.name.length > 16
                              ? item.name.substring(0, 15) + '...'
                              : item.name
                          }}》
                          <span>
                            {{ item.schedule_name }}
                          </span>
                        </div>

                        <div v-else class="name" :title="item.name">
                          {{ item.name }}
                        </div>
                      </div>
                      <div class="type_price">
                        <div class="sp" v-if="ruleForm.type == 3">公开课</div>
                        <div class="sp" v-else>
                          {{
                            item.course_type == 1
                              ? '直播课'
                              : item.course_type == 2
                              ? '小班课'
                              : item.course_type == 3
                              ? '录播课'
                              : '系列课'
                          }}
                        </div>
                        <!-- 公开课 -->
                        <div class="sp redcolor" v-if="ruleForm.type == 3">
                          {{
                            item.play_type == 3
                              ? `￥${item.play_price}`
                              : item.play_type == 1
                              ? '免费'
                              : '密码'
                          }}
                        </div>
                        <div class="sp redcolor" v-else-if="ruleForm.type == 1">
                          <span v-if="item.price_type == 1">
                            {{ '￥' + item.price }}
                          </span>
                          <span v-if="item.price_type == 2">
                            {{ '免费' }}
                          </span>
                          <span v-if="item.price_type == 3">
                            {{ '密码' }}
                          </span>
                        </div>
                        <div class="sp redcolor" v-else>
                          <span v-if="item.price > 0">￥{{ item.price }}</span>
                          <span v-else>免费</span>
                        </div>

                        <el-popconfirm
                          confirm-button-text="确定"
                          cancel-button-text="取消"
                          icon="el-icon-info"
                          icon-color="red"
                          title="是否确定移除该内容？"
                          @confirm="remove(index)"
                          v-if="isnew || ruleForm.theme_count == 0"
                        >
                          <el-button slot="reference" type="text" class="sp">
                            移除
                          </el-button>
                        </el-popconfirm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>

          <!-- <el-form-item label="提交截止时间">
            <el-date-picker
              v-model="ruleForm.last_submit_time"
              type="datetime"
              default-time="00:00:00"
              placeholder="选择日期时间"
              value-format="timestamp"
              format="yyyy-MM-dd HH:mm"
              :picker-options="pickerOptions"
            ></el-date-picker>

            <span style="font-size: 12px; color: #666666" class="ml20">
              选填；选择后，设置的时间过期后，未提交作业的学员无法提交，有助于学员按时完成
            </span> 
          </el-form-item> -->
        </el-form>
        <div class="btns">
          <el-button type="primary" @click.prevent="preservation">
            保存
          </el-button>
          <p>提示：保存后会进入管理页，可进行添加作业</p>
        </div>
      </div>
    </div>

    <!-- 选择公开课 -->
    <networkschool-select
      v-if="selectChangestatus"
      :singleselect="true"
      :dialogstatus="!!selectChangestatus"
      :id_type="1"
      :id="$route.params.id == 'new' ? undefined : $route.params.id"
      title="选择公开课"
      tip="温馨提示：已添加的直播不在列表展示"
      :template="16"
      :ids="selectChangestatusids"
      @selectChangenetworkschool="selectChangenetworkschool"
      @singleselectcontentval="singleselectcontentval"
    ></networkschool-select>

    <!-- 选择章节 -->
    <selectChapter
      v-if="selectChapterstatus"
      :singleselect="true"
      :dialogstatus.sync="selectChapterstatus"
      :list="course"
      @chapters="chapters"
    ></selectChapter>

    <!-- 查看示例 -->
    <el-dialog
      title="查看示例"
      :visible.sync="examplesdialogVisible"
      width="800px"
    >
      <span slot="title" class="fs14">查看示例</span>
      <img src="~@ass/img/1.4.1.2/img_lxslt.png" alt="" class="shili" />
    </el-dialog>

    <!-- 获取已选课程列表 -->
    <had-selectcourse
      @course="lastCourse"
      v-if="status"
      :course="course"
      :dialogstatus.sync="status"
      @coursenew="coursenew"
    ></had-selectcourse>

    <!-- 课程选择 -->
    <el-dialog
      title="选择课程"
      width="800px"
      :visible.sync="dialogSelectVisible"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div slot="title">
        选择课程
        <span style="color: #ff3535; font-size: 13px">
          温馨提示：已添加的课程不在列表展示
        </span>
      </div>
      <dialog-selectcourse
        :showPassword="1"
        :is_workbook="1"
        :singleselect="true"
        ref="dialogRef"
        :type="3"
      ></dialog-selectcourse>
    </el-dialog>
  </div>
</template>
<script>
// 选择公开课
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
import hadSelectcourse from '@/components/base/hadSelectcourse'
import dialogSelectcourse from '@/components/base/dialogSelectcourse'
import selectChapter from '@cm/base/selectChapter'
export default {
  // mixins: [bg],
  name: 'newData',
  data() {
    return {
      selectChangestatusids: [],

      selectChangestatus: false,

      examplesdialogVisible: false, //示例弹窗

      group_id1: '', //选中分组id

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        },
      },

      selectChapterstatus: false, //选择章节弹窗

      status: false, //已选课程弹窗

      course: [], //选择的课程

      dialogSelectVisible: false, //控制选择课程

      value2: '', //选择的时间

      //基础信息
      ruleForm: {
        type: '1',
        name: '',
        last_submit_time: '',
        list: [],
      },

      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    isnew() {
      return this.$route.params.id == 'new'
    },

    title() {
      return this.$store.state.userInfo.jg_title
    },
  },

  mounted() {
    if (this.$route.params.id != 'new') {
      this.getdata()
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '编辑作业'
    }
    // this.setToken()
  },

  components: {
    // upload,
    // workaddpicture,
    // uploadImg,
    networkschoolSelect,
    dialogSelectcourse,
    hadSelectcourse,
    selectChapter,
  },

  methods: {
    //选择公开课
    selectOpenCourse() {
      this.selectChangestatusids = this.course.map(item => item.open_class_id)
      this.selectChangestatus = true
    },

    //选择数据的回调
    singleselectcontentval(val) {
      this.course = [val]
      this.selectChangenetworkschool()
    },

    // 关闭弹窗
    selectChangenetworkschool() {
      this.selectChangestatus = false
    },

    //关闭选择课程弹窗
    cancelDialog() {
      this.dialogSelectVisible = false
    },

    selectchapter() {
      this.selectChapterstatus = true
    },

    //移除
    remove(val) {
      this.course.splice(val, 1)
    },

    coursenew(val) {
      this.course = val
    },

    lastCourse(val) {
      this.course = val
    },

    //获取章节内容跟
    chapters(val) {
      this.course = [val]
    },

    //获取选择的图片
    list(val) {
      this.img_url = val.url
      this.ruleForm.images_id = val.images_id
    },

    // 上传弹窗关闭
    uploadConfirmClose() {
      this.$refs.workaddpicture.renew()
      this.uploadStart = false
    },

    // 本地上传图片
    locationUpload() {
      this.uploadStart = true
    },

    //已选择课程
    hadcourse() {
      this.status = true
    },

    //选择课程
    chooseCourse() {
      //点击选择课程遍历拿到课程id传给后台
      this.dialogSelectVisible = true
      //重新拉取树形菜单
      let _this = this
      this.$nextTick(() => {
        _this.$refs.dialogRef.getMenu(_this.course, _this.member_id)
        _this.$refs.dialogRef.clearSelect()
      })
    },

    gbDialog(list) {
      //搭配组件使用的方法,关闭弹出框
      this.dialogSelectVisible = false
      this.course = list
    },

    radioChange() {
      this.course = []
    },

    getdata() {
      this.$http({
        url: '/Homework/editHomework?homework_id=' + this.$route.params.id,
        // data: {
        //   id: this.$route.params.id,
        // },
        callback: ({ data }) => {
          this.ruleForm = data
          this.ruleForm.last_submit_time =
            data.last_submit_time == 0 ? '' : data.last_submit_time * 1000
          this.course = data.list
        },
      })
    },

    //保存内容
    preservation() {
      this.ruleForm.name = this.ruleForm.name.trim()
      this.$nextTick(() => {
        this.$refs.ruleFormData.validate(valid => {
          if (valid) {
            // alert('submit!');
          } else {
            return false
          }
        })
      })

      const post = _.assign({}, this.ruleForm, {
        last_submit_time: this.ruleForm.last_submit_time / 1000,
        list: this.course,
        name: this.ruleForm.name.trim(),
      })

      if (post.name == '') {
        this.$root.prompt({
          msg: '请输入作业名称',
        })
        return
      }

      if (this.course.length == 0) {
        this.$root.prompt({
          msg: '请选择关联内容',
        })
        return
      }

      if (this.$route.params.id !== 'new') {
        this.$http({
          url: '/Homework/editHomework',
          data: post,
          callback: () => {
            this.$router.replace({
              path: '/courseWorkbook/editor/' + this.$route.params.id,
            })
          },
          error: ({ info }) => {
            this.$root.prompt(info)
          },
        })
      } else {
        this.$http({
          url: 'Homework/createHomework',
          data: post,
          callback: ({ data }) => {
            this.$root.prompt({
              msg: '保存成功',
              type: 'success',
            })
            this.$router.replace({
              path: '/courseWorkbook/editor/' + data,
              // query: {
              //   id: data
              // },
            })
          },
          error: ({ info }) => {
            this.$root.prompt(info)
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-pagination {
  transform-origin: left !important;
}
::v-deep .el-form-item__label {
  color: #333333;
}
.typecourse {
  margin-top: 20px;
  .btn {
    .tip1 {
      margin-left: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
    }
    .had_select_course {
      display: flex;
      justify-content: space-between;
      .tu_name {
        width: calc(100% - 211px);
        display: flex;
        align-items: center;
        .tu {
          width: 40px;
          height: 23px;
          margin-right: 10px;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .name {
          width: calc(100% - 50px);
          font-size: 13px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .type_price {
        display: flex;
        align-items: center;
        .sp {
          font-size: 13px;
          line-height: 13px;
          width: 70px;
          text-align: center;
        }
      }
    }
  }
}
.shili {
  width: 592px;
  height: auto;
  margin: 0 auto;
}

.down_sum {
  margin-top: 20px;
  display: flex;

  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }

  .is {
    margin-left: 40px;
  }

  .inputBox {
    p {
      width: 100px;
    }

    display: flex;
    width: 300px;
  }
}

.flexBox {
  margin-top: 10px;
  display: flex;

  .schoolstyle {
    margin-left: 10px;
    margin-top: -2px;
  }

  .schoolstyle_1 {
    margin-top: -3px;
  }
}

.priceType {
  .el-form-item {
    margin-bottom: 15px;

    ::v-deep .el-form-item__label {
      width: 70px !important;
    }

    ::v-deep .el-form-item__content {
      margin-left: 70px !important;
      margin-top: 5px;
    }
  }
  .courseSelect {
    margin-top: 20px;
    .el-button:last-child {
      margin-left: 20px;
    }
    .courseSelecttip {
      margin-top: 20px;
      font-size: 12px;
      color: #666666;
      line-height: 12px;
    }
  }
}

// ::v-deep .pcWxUploadBtn {
//   left: 159px;
//   width: 100%;
// }

::v-deep .showUploadPs {
  width: 260px;
  left: 160px;
}

.fontcolor_green {
  ::v-deep .el-button {
    border-color: #1b9d97;
    color: #1b9d97;
  }
}

.fengxiang {
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  text-align: center;
  margin: 115px 0 138px;
}

.dialogVisible_main {
  width: 280px;
  margin: 0 auto;
  position: relative;

  .qrcode {
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px;
    height: 118px;
    width: 118px;
    background-color: #fff;
    position: absolute;
    top: 49px;
    left: 50%;
    transform: translateX(-50%);
  }

  .font1 {
    height: 92px;
    width: 100%;
    position: absolute;
    top: 187px;

    .font_first {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      line-height: 18px;
    }

    .font_scond {
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
      margin: 12px 0 25px;
    }

    .font_third {
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
    }
  }

  .btns {
    display: flex;
    justify-content: center;

    a {
      text-decoration: none;
      color: #0aa29b;
    }
  }

  .result-pic {
    width: 280px;
    height: 314px;
    position: absolute;
    top: 0px;
    left: 0px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

::v-deep .el-tabs__nav-wrap:after {
  height: 0;
}

::v-deep .el-popconfirm__action {
  margin-top: 10px !important;
}

::v-deep .el-select .el-input .el-select__caret {
  height: 36px;
}

.danwei {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-left: 10px;
}

::v-deep .el-form-item {
  margin-bottom: 30px;
}

.w-e-text-container {
  height: 500px !important;
}

#newData {
  display: flex;
  padding: 20px;
  box-sizing: border-box;

  .newDataAll {
    width: 100%;
    background: #fff;
    overflow: hidden;
  }

  .minwidth {
    min-width: 683px;
  }

  .seting {
    position: relative;
    // width: calc(100% - 343px);
    background-color: #fff;
    padding: 20px;

    ::v-deep .el-tabs__content {
      padding: 10px 20px 20px;
    }

    .seting_font1 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
      margin: 20px 0px 30px 20px;
    }

    .schoolstyle {
      font-size: 12px;
      color: #666666;
      line-height: 14px;
    }

    .editor {
      margin-bottom: 40px;
    }
    .deadline {
      display: flex;
      align-items: center;
      min-height: 40px;
      .zi1 {
        font-size: 12px;
        color: #666666;
        line-height: 12px;
        margin-left: 32px;
      }
    }

    .btns {
      margin-bottom: 20px;
      bottom: 0;
      display: flex;

      p {
        width: 324px;
        font-size: 12px;
        color: #ff3535;
        line-height: 40px;
        white-space: nowrap;
      }

      .el-button {
        width: 126px;
        margin-right: 20px;
      }
    }

    .Specifications {
      display: flex;

      .left {
        width: 112px;
        display: flex;

        .font1 {
          margin-left: 3px;
          font-size: 14px;
          color: #333333;
          line-height: 19px;
        }
      }

      .right {
        width: calc(100% - 112px);

        .format {
          position: relative;
          margin-bottom: 20px;
          width: 404px;
          height: 336px;
          background: #f5f5f5;
          padding: 20px;
          overflow: hidden;
          box-sizing: border-box;

          ::v-deep .el-form-item {
            margin-bottom: 17px;
          }

          .close {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .membershipCourse {
      .header {
        display: flex;
        align-items: center;

        .font1 {
          font-size: 12px;
          color: #666666;
          line-height: 16px;
          margin-left: 20px;
        }
      }

      .main {
        .empty {
          font-size: 12px;
          color: #666666;
          text-align: center;
          line-height: 16px;
          margin-top: 169px;
        }

        .main_course {
          margin-top: 30px;
          margin-bottom: 60px;
        }
      }
    }

    .btns2 {
      margin-left: 113px;
      margin-bottom: 95px;

      .btns2_font {
        font-size: 13px;
        color: #999999;
        margin-left: 20px;
        line-height: 13px;
      }
    }
  }
}

.course-pic-wrap {
  width: 518px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    object-fit: contain;
    width: 220px;
    height: 124px;
  }

  .p-wrap {
    .txt {
      margin-bottom: 22px;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      line-height: 16px;
    }

    .btn-wrap {
      .warning {
        margin-left: 10px;
        font-size: 12px;
        color: rgba(255, 53, 53, 1);
        line-height: 16px;
      }
    }
  }
}
</style>
